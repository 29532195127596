import { onIntersectionObserver } from '@naturehouse/nh-essentials/lib/events/eventHandling';

export default class NumberCounter extends HTMLElement {
    #done = false;

    readonly #value = Number(this.getAttribute('value'));

    public connectedCallback(): void {
        if ('IntersectionObserver' in window) {
            onIntersectionObserver(this, () => this.#animateCounter.bind(this));
            return;
        }

        this.#animateCounter();
    }

    #animateCounter(): void {
        if (this.#done) {
            return;
        }

        const duration = 1500;

        const startValue = 0;
        const endValue = this.#value;
        const range = endValue - startValue;

        const minTimer = 50;
        const stepTime = Math.max(Math.abs(Math.floor(duration / range)), minTimer);

        const startTime = new Date().getTime();
        const endTime = startTime + duration;
        let timer: number | null = null;

        const locale: string =
            document.documentElement.dataset.locale?.replace('_', '-') || 'nl-NL';
        const numberFormatter: Intl.NumberFormat = new Intl.NumberFormat(locale, {
            style: 'decimal'
        });

        const run = (): void => {
            const now = new Date().getTime();
            const remaining = Math.max((endTime - now) / duration, 0);
            const value = Math.round(endValue - remaining * range);

            this.innerHTML = numberFormatter.format(value);

            if (value.toString() === endValue.toString() && timer !== null) {
                clearInterval(timer);
            }
        };

        timer = window.setInterval(run, stepTime);
        run();
        this.#done = true;
    }
}

if (!customElements.get('number-counter')) {
    customElements.define('number-counter', NumberCounter);
}
