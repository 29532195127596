import type { DefaultComponentType } from '../../../../src/utils/types';
import './Avatar.pcss';

export type AvatarProps = DefaultComponentType & {
    initials: string;
    imageUrl?: string;
};

export default class Avatar extends HTMLElement {
    #imageUrl = this.getAttribute('imageUrl');

    set imageUrl(value: string) {
        this.#imageUrl = value;
    }

    #initials = this.getAttribute('initials');

    set initials(value: string) {
        this.#initials = value;
    }

    public connectedCallback(): void {
        if (this.#imageUrl) {
            const image: HTMLImageElement = document.createElement('IMG') as HTMLImageElement;
            image.src = this.#imageUrl ?? '';
            this.appendChild(image);

            return;
        }

        if (this.#initials) {
            this.innerText = this.#initials ?? '';
            this.style.backgroundColor = this.#getColorFromText(this.#initials ?? '');
        }
    }

    #getColorFromText(text: string): string {
        const colors = [
            '#00AA55',
            '#009FD4',
            '#B381B3',
            '#939393',
            '#E3BC00',
            '#D47500',
            '#DC2A2A'
        ];
        const charCodes = text
            .split('')
            .map((char) => char.charCodeAt(0))
            .join('');

        return colors[parseInt(charCodes, 10) % colors.length];
    }
}

if (!window.customElements.get('nh-avatar')) {
    window.customElements.define('nh-avatar', Avatar);
}
